import {HttpInterceptorFn, HttpRequest} from '@angular/common/http'

/**
 * Use this header to prevent any buildigo specific HTTP interceptors from adding any extra header to a request.
 *
 * This can be useful with signed urls, where headers are part of the computed signature
 * or when interacting with another API that's not owned by Buildigo and would reject some headers we add.
 */
export const preventBgoProprietaryHttpInterceptorHeaderName = 'x-bgo-no-interceptor'

export const preventBgoProprietaryHttpInterceptorHeader = {
  [preventBgoProprietaryHttpInterceptorHeaderName]: 'true',
}

export const shouldNotIntercept = (req: HttpRequest<unknown>): boolean => {
  return req.headers.has(preventBgoProprietaryHttpInterceptorHeaderName) || isJsonpRequest(req)
}

export const isJsonpRequest = (req: HttpRequest<unknown>): boolean => {
  return req.method === 'JSONP'
}

export const PreventInterceptor: HttpInterceptorFn = (req, next) => {
  let reqClone = req
  // if preventAuthInterceptorHeader is present, remove it and do not add any extra header
  if (req.headers.has(preventBgoProprietaryHttpInterceptorHeaderName)) {
    reqClone = req.clone({headers: req.headers.delete(preventBgoProprietaryHttpInterceptorHeaderName)})
  }

  return next(reqClone)
}
